var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('Header'),_c('div',{staticClass:"banner"}),_c('div',{staticClass:"case"},[_c('div',{staticClass:"synopsis"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"p2"},[_vm._v("让企业快速提升管理水平")]),_vm._m(2),_c('div',{staticClass:"caption-box"},[_c('div',{staticClass:"caption"},[_vm._v("精选案例")]),_c('div',{staticClass:"line"}),_c('p',[_vm._v("涉及行业累积100+，获得用户高度评价")]),_c('div',{staticClass:"linellae"}),_c('div',{staticClass:"caption-content"},[_c('div',{staticClass:"caption-item",on:{"click":_vm.linkTo}},[_c('div',{staticClass:"seeBtn"},[_vm._v("查看更多>>>")]),_vm._m(3)]),_c('div',{staticClass:"caption-item",on:{"click":_vm.linkTo}},[_c('div',{staticClass:"seeBtn"},[_vm._v("查看更多>>>")]),_vm._m(4)]),_c('div',{staticClass:"caption-main"},_vm._l((_vm.caption),function(item,index){return _c('div',{key:index,staticClass:"caption-main-item",on:{"click":_vm.linkTo}},[_c('img',{attrs:{"src":item.img,"alt":""}}),_c('div',{staticClass:"seeBtn"},[_vm._v("查看更多>>>")]),_vm._m(5,true)])}),0)])]),_vm._m(6),_c('div',{staticClass:"more-box"},[_c('div',{staticClass:"linellae"}),_c('div',{staticClass:"more-content"},_vm._l((_vm.more),function(item,index){return _c('div',{key:index,staticClass:"more-item",on:{"click":_vm.linkTo}},[_c('img',{attrs:{"src":item.img,"alt":""}}),_c('div',{staticClass:"more-text"},[_c('div',[_vm._v(_vm._s(item.title))]),_c('p',[_vm._v(_vm._s(item.text))]),_c('div',[_vm._v(_vm._s(item.btn))])])])}),0)])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('span',[_vm._v("中国咨询行业")]),_c('span',[_vm._v("领先者与开拓者")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"p1"},[_vm._v("为企业提供"),_c('span',[_vm._v("“咨询+IT”")]),_vm._v("一体化解决方案")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"btn"},[_c('img',{attrs:{"src":require("../../assets/mobileImgs/btn.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"matter"},[_c('div',{staticClass:"topic"},[_vm._v("佛山水业集团公司")]),_c('div',{staticClass:"text"},[_vm._v("景翔助力深圳金地物业打造医院物业标杆项目景翔助力深圳金地物业打造医院物业标杆项目")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"matter"},[_c('div',{staticClass:"topic"},[_vm._v("佛山水业集团公司")]),_c('div',{staticClass:"text"},[_vm._v("景翔助力深圳金地物业打造医院物业标杆项目景翔助力深圳金地物业打造医院物业标杆项目")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"matter"},[_c('div',{staticClass:"topic"},[_vm._v("佛山水业集团公司")]),_c('div',{staticClass:"text"},[_vm._v("2022-3-23广东达宜明MSA培训")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bgBox"},[_c('div',{staticClass:"caption-box"},[_c('div',{staticClass:"caption"},[_vm._v("更多案例")]),_c('div',{staticClass:"line"})])])
}]

export { render, staticRenderFns }