<template>
  <div class="container">
    <Header></Header>
    <div class="banner"></div>
    <div class="case">
        <div class="synopsis">
            <div class="title"><span>中国咨询行业</span><span>领先者与开拓者</span></div>
            <div class="p1">为企业提供<span>“咨询+IT”</span>一体化解决方案</div>
            <div class="p2">让企业快速提升管理水平</div>
            <div class="btn">
                <img src="../../assets/mobileImgs/btn.png" alt="">
            </div>
            <div class="caption-box">
                <div class="caption">精选案例</div>
                <div class="line"></div>
                <p>涉及行业累积100+，获得用户高度评价</p>
                <div class="linellae"></div>
                <div class="caption-content">
                    <div class="caption-item" @click="linkTo">
                        <div class="seeBtn">查看更多>>></div>
                        <div class="matter">
                            <div class="topic">佛山水业集团公司</div>
                            <div class="text">景翔助力深圳金地物业打造医院物业标杆项目景翔助力深圳金地物业打造医院物业标杆项目</div>
                        </div>
                    </div>
                    <div class="caption-item" @click="linkTo">
                        <div class="seeBtn">查看更多>>></div>
                        <div class="matter">
                            <div class="topic">佛山水业集团公司</div>
                            <div class="text">景翔助力深圳金地物业打造医院物业标杆项目景翔助力深圳金地物业打造医院物业标杆项目</div>
                        </div>
                    </div>
                    <div class="caption-main">
                        <div class="caption-main-item" v-for="(item,index) in caption" :key="index" @click="linkTo">
                            <img :src="item.img" alt="">
                            <div class="seeBtn">查看更多>>></div>
                            <div class="matter">
                                <div class="topic">佛山水业集团公司</div>
                                <div class="text">2022-3-23广东达宜明MSA培训</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="bgBox">
                <div class="caption-box">
                    <div class="caption">更多案例</div>
                    <div class="line"></div>
                </div>
            </div>
            <div class="more-box">
                <div class="linellae"></div>
                <div class="more-content">
                    <div class="more-item" v-for="(item,index) in more" :key="index" @click="linkTo">
                        <img :src="item.img" alt="">
                        <div class="more-text">
                            <div>{{item.title}}</div>
                            <p>{{item.text}}</p>
                            <div>{{item.btn}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import Header from '../../components/mobile/Header.vue';
export default {
    components:{
        Header
    },
    data(){
        return {
            caption:[
                {
                    img:require("../../assets/mobileImgs/bg4.png"),
                    title:"佛山水业集团公司",
                    text:"2022-3-23广东达宜明MSA培训",
                },
                {
                    img:require("../../assets/mobileImgs/bg3.png"),
                    title:"佛山水业集团公司",
                    text:"2022-3-23广东达宜明MSA培训",
                },
                {
                    img:require("../../assets/mobileImgs/bg3.png"),
                    title:"佛山水业集团公司",
                    text:"2022-3-23广东达宜明MSA培训",
                },
                {
                    img:require("../../assets/mobileImgs/bg3.png"),
                    title:"佛山水业集团公司",
                    text:"2022-3-23广东达宜明MSA培训",
                },
            ],
            more:[
                {
                    img:require("../../assets/mobileImgs/bg5.png"),
                    title:"深圳金地物业",
                    text:"景翔助力深圳金地物业打造医物业标杆项目",
                    btn:"体系维护"
                },
                 {
                    img:require("../../assets/mobileImgs/bg5.png"),
                    title:"深圳金地物业",
                    text:"景翔助力深圳金地物业打造医物业标杆项目",
                    btn:"体系维护"
                },
                 {
                    img:require("../../assets/mobileImgs/bg5.png"),
                    title:"深圳金地物业",
                    text:"景翔助力深圳金地物业打造医物业标杆项目",
                    btn:"体系维护"
                },
                 {
                    img:require("../../assets/mobileImgs/bg5.png"),
                    title:"深圳金地物业",
                    text:"景翔助力深圳金地物业打造医物业标杆项目",
                    btn:"体系维护"
                },
            ]
        }
    },
    methods:{
        linkTo(){
           this.$router.push("/solutionDetails")
        }
    }
}
</script>

<style lang="less" scoped>
.container{
    .banner{
        background: url("../../assets/mobileImgs/banner1.png") no-repeat;
        background-size: 100% 100%;
        width: 100%;
        height: 4.89rem;
    }
    .case{
        width: 100%;
        .synopsis{
            text-align: center;
            padding-top: .54rem;
            background-color: #ffffff;
            .title{
                height: 0.42rem;
                font-size: 0.44rem;
                font-weight: bold;
                line-height: 0.42rem;
                span:nth-child(1){
                    color: #000000;
                }
                span:nth-child(2){
                    color: rgba(207, 20, 29, 1);
                }
            }
            .p1{
                height: 0.28rem;
                font-size: 0.28rem;
                font-weight: bold;
                color: #000000;
                line-height: 0.28rem;
                margin-top: .46rem;
                span{
                    color: rgba(207, 20, 29, 1);
                }
            }
            .p2{
                height: 0.26rem;
                font-size: 0.28rem;
                font-weight: bold;
                color: #000000;
                line-height: 0.26rem;
                margin-top: .3rem;
            }
            .btn{
                width: 4.1rem;
                height: 0.67rem;
                background: #4C83F6;
                border-radius: 0.08rem;
                margin: .36rem auto;
                display: flex;
                align-items: center;
                justify-content: center;
                img{
                    width: 3.56rem;
                    height: .29rem;
                }
            }
            .caption-box{
                width: 100%;
                padding: 0 .2rem;
                box-sizing: border-box;
                .caption{
                    height: 0.32rem;
                    font-size: 0.32rem;
                    font-weight: bold;
                    color: #434343;
                    line-height: 0.32rem;
                    margin-top: .58rem;
                }
                .line{
                    width: .43rem;
                    border: .04rem solid #000000;
                    margin: .2rem auto;
                }
                p{
                    height: 0.26rem;
                    font-size: 0.26rem;
                    font-weight: 400;
                    color: #666666;
                    line-height: 0.26rem;
                    margin-top: .32rem;
                }
                .linellae{
                    height: .02rem;
                    background: #D4D4D4;
                    box-shadow: 0px .02rem .05rem .02rem rgba(210,210,210,0.81);
                    margin-top: .22rem;
                }
                .caption-content{
                    .seeBtn{
                        width: 2.09rem;
                        height: 0.46rem;
                        background: #CF141D;
                        border-radius: 0.08rem;
                        font-size: 0.28rem;
                        font-weight: 500;
                        color: #FFFFFF;
                        line-height: 0.46rem;
                        position: absolute;
                        top: .16rem;
                        right: .24rem;
                    }
                    .matter{
                        text-align: left;
                        position: absolute;
                        left: .18rem;
                        bottom: .14rem;
                        .topic{
                            height: 0.4rem;
                            font-size: 0.42rem;
                            font-weight: bold;
                            color: #FFFFFF;
                            line-height: 0.4rem;
                            text-shadow: 0px 0.09rem 0.16rem rgba(0,0,0,0.81);
                        }
                        .text{
                            width: 6.38rem;
                            font-size: 0.28rem;
                            font-weight: 400;
                            color: rgba(255, 255, 255, 0.8);
                            line-height: 0.36rem;
                            text-shadow: 0px 0.09rem 0.16rem rgba(0,0,0,0.81);
                            margin-top: .25rem;
                        }
                    }
                    .caption-item{
                        position: relative;
                        height: 3.57rem;
                        border-radius: 0.13rem;
                        background: url("../../assets/mobileImgs/bg2.png") no-repeat;
                        background-size: 100% 100%;
                        margin-top: .16rem;
                    }
                    .caption-main{
                        display: flex;
                        justify-content: space-between;
                        flex-wrap: wrap;
                        .caption-main-item{
                            position: relative;
                            width: calc(50% - .1rem);
                            height: 2.62rem;
                            margin-top: .16rem;
                            img{
                                width: 100%;
                                height: 100%;
                                border-radius: 0.13rem;
                            }
                            .seeBtn{
                                width: 1.67rem;
                                position: absolute;
                                top: .08rem;
                                right: .08rem;
                                font-size: .26rem;
                            }
                            .matter{
                                position: absolute;
                                left: .1rem;
                                bottom: .1rem;
                                .topic{
                                    font-size: .38rem;
                                }
                                .text{
                                    font-size: .22rem;
                                    margin-top: .2rem;
                                }
                            }
                        }
                    }
                }
            }
            .bgBox{
                width: 100%;
                height: 1.9rem; 
                background: url("../../assets/mobileImgs/bg6.png") no-repeat;
                background-size: 100% 100%;  
                padding-top: 1rem;
                box-sizing: border-box;
                .caption{
                    margin-top: 0;
                }
            }
            .more-box{
                width: 100%;
                background: #ffffff;
                padding: 0 .2rem .66rem;
                box-sizing: border-box;
                .linellae{
                    height: .02rem;
                    background: #D4D4D4;
                    box-shadow: 0px .02rem .05rem .02rem rgba(210,210,210,0.81);
                    margin-bottom: .45rem;
                }
                .more-content{
                    .more-item{
                        height: 2.05rem;
                        border: 1px solid #F9F9F9;
                        box-shadow: 0px 0.09rem 0.16rem 0px rgba(0,0,0,0.07);
                        border-radius: 0.06rem;
                        margin-top: .2rem;
                        display: flex;
                        img{
                            width: 1.74rem;
                            height: 100%;
                        }
                        .more-text{
                            margin-left: .22rem;
                            text-align: left;
                            &>div:nth-child(1){
                                height: 0.3rem;
                                font-size: 0.3rem;
                                font-weight: bold;
                                color: #000000;
                                line-height: 0.3rem;
                                margin-top: .4rem;
                            }
                            p{
                                width: 4.48rem;
                                font-size: 0.28rem;
                                font-weight: 400;
                                color: #787878;
                                line-height: 0.3rem;
                                margin: .08rem 0;
                            }
                             &>div:nth-child(3){
                                width: 1.29rem;
                                height: 0.35rem;
                                background-color: #F0F1F4;
                                font-size: 0.26rem;
                                font-weight: 400;
                                color: #787878;
                                line-height: 0.35rem;
                                text-align: center;
                             }
                        }
                    }
                }
            }
        }
    }
}
</style>